.app-job-tabs {
  background-position: center;
  background-size: cover;

  .tabs {
    display: flex;
    justify-content: center;

    @media (max-width: 600px) {
      display: block;
    }

    .tabs-links {
      box-shadow: 0px 0px 0px 10px rgba(0,0,0,0.25);
      background-color: #6C757D;
      border-radius: 25px;
    }

    a {
      color: #dee2e6;
      display: inline-block;
      padding: 10px 20px;      

      &:first-child {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }

      &:last-child {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }

      &.active {
        background-color: #fff;
        color: #c42131;
      }

      @media (max-width: 600px) {
        display: block;
        padding: 10px 10px;

        &:first-child {
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          border-bottom-left-radius: 0;
        }
  
        &:last-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 25px;
          border-bottom-left-radius: 25px;
        }
      }
    }
  }

  .app-jobs-tab {
    display: none;
  }
  
  #stellenangebote {
    display: block;
    background-image: none !important;
  }

  #jobs-list {
    background-image: none !important;

    .row {
      justify-content: center;
    }
  }
  
  .jobs-list-content {
    background-color: #fff;

    strong {
      display: block;
      border-bottom: 1px solid;
      padding-bottom: 2px;
      color: #c42131;
    }

    ul {
      padding: 0;
      list-style: none;
      column-gap: 20px;
      columns: 5 210px;

      li {
        a {
          color: #555;

          &:hover {
            color: #c42131;
          }
        }
      }
    }
  }
}
//Landingpage styles
.accordion-h4 h4 {
  font-size: 16px;
  margin-bottom: 0px;
  font-weight:normal;
  margin-top: 8px;
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 4px;
  padding: 10px 20px;
  padding-left: 40px;
  cursor: pointer;
  position: relative;
  color: black;
  &:before {
    content:"+";
    font-size: 20px;
    font-weight: 300;
    color: black;
    position: absolute;
    left:18px;
    top:7px;
    transition: transform 0.3s cubic-bezier(0.78, 0, 0.1, 1), color 0.3s cubic-bezier(0.78, 0, 0.1, 1);
  }
  &.active {
    &:before {
      content:"+";
      transform: rotate(45deg);
      color: black;
     
    }

  }
  &:hover {
    color: #c42131;
  }
 
}

.accordion-content {
  display: none;
  padding: 20px 20px;
  border-bottom: 10px;
  background-color: rgba(0,0,0,.05);
  margin-bottom: 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .1);
  font-weight:normal;
}

.co-angebot {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.co-angebot li {
  background: #dee2e6;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 20px;
  padding-left: 90px;
  padding-right: 40px;
  position: relative;
  border: solid 10px white;
  font-weight: 700;
  width: 100%;
  min-height: 140px;
  margin-bottom: 0;
}

.co-angebot li:before {
  position: absolute;
  top: 0px;
  left: 10px;
  display: block;
  width: 70px;
  text-align: center;
  content: "1";
  font-size: 100px;
  font-weight: 300;
  color: #c42131;
}


.co-angebot li:nth-child(2):before {
  content: "2";
}

.co-angebot li:nth-child(3):before {
  content: "3";
}

.co-angebot li:nth-child(4):before {
  content: "4";
}

.co-angebot li:nth-child(5):before {
  content: "5";
}

.co-angebot li:nth-child(6):before {
  content: "6";
}

.co-angebot li:nth-child(7):before {
  content: "7";
}

.co-angebot li:nth-child(8):before {
  content: "8";
}

.ly-lp {
  font-weight: 400;

}

.ly-lp strong {
  font-weight: 700;

}

.accordion-section-title {
  cursor: pointer;
  font-weight: 300;
  font-size: 24px;
  border-bottom: 1px solid rgba(0,0,0,.05);
  padding: 10px 0;
  position: relative;
  transition: border-volor .3s, color .3s;
}

.accordion-section-title:hover {
  color: #c42131;
  border-bottom: 1px solid #c42131;
}

.accordion-section-title:after {
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  content: "+";
  position: absolute;
  right: 0px;
  margin-left: 5px;
}

.accordion-section-title.active:after {
  content: "–";
}

.accordion-sectoion-content {
  display: none;
  padding: 30px 0;
}
@media screen and(min-width:760px) {
  .co-angebot li {
    width: 50%;
  }
}
@media screen and(min-width:1040px) {
  .co-angebot li {
    width: 33%;
  }
}